import React from "react"
import Layout from "../components/layout"

const Apps = () => {
  return (
    <Layout>
      <h1>Apps list</h1>
      <p>Cool apps and shit </p>
    </Layout>

  )
}

export default Apps